export const ValidationMessages = {
  requiredField: "Ce champ ne doit pas être vide",
  requiredFile: "Un fichier doit être sélectionné",
  requiredJointFields: "Au moins un de ces champs doit être rempli",
  requiredSelect: "Vous devez sélectionner une option",
  requiredRadio: "Vous devez sélectionner une option",
  requiredDate: "Vous devez sélectionner une date",
  // eslint-disable-next-line
  stringTooShort: "Trop court (min. ${min} caractères)",
  // eslint-disable-next-line
  stringTooLong: "Trop long (max. ${max} caractères)",
  invalidUrl: "Adresse web invalide",
  invalidEmail: "Adresse email invalide",
  invalidPhoneNumber: "N° de téléphone invalide",
  invalidSiren: "N° SIREN invalide",
  expectedMobilePhone: "Un n° de téléphone mobile est attendu",
  invalidPostCode: "Code postal invalide",
  imageFormatPngOrJpg: "L'image doit être au format .jpg ou .png",
  // eslint-disable-next-line
  fileOverXMegaBytes: "Le fichier est trop gros (${max} Mo maximum)",
  needAtLeastOneLowerCase: "Doit contenir au moins une lettre minuscule",
  needAtLeastOneUpperCase: "Doit contenir au moins une lettre majuscule",
  needAtLeastOneDigit: "Doit contenir au moins un chiffre",
  needAtLeastOneSpecialChar: "Doit contenir au moins un caractère spécial",
  passwordConfirmDoesNotMatch: "Le mot de passe est différent",
  newEmailMustDifferFromFormer: "Adresse identique à l'actuelle",
  numberExpected: "La valeur n'est pas un nombre",
  integerExpected: "La valeur n'est pas un nombre entier",
  invalidDate: "Date invalide",
  tooManyDecimals: "Trop de décimales",
  // eslint-disable-next-line
  belowMinValue: "La valeur doit être supérieure ou égale à ${min}",
  // eslint-disable-next-line
  aboveMaxValue: "La valeur doit être inférieure ou égale à ${max}",
  // eslint-disable-next-line
  notAPdf: '"${file}" n\'est pas un document pdf',
  // eslint-disable-next-line
  specificCountRequired: "Vous devez sélectionner exactement ${count} fichiers",
  oneFileRequired: "Vous devez sélectionner exactement un seul fichier",
  noFuture: "Vous ne pouvez pas entrer une date future",
  notBeforeLastStatusChange: "Vous ne pouvez pas entrer une date antérieure au dernier changement de statut",
  noSemiColon: "Caractère interdit : point-virgule (;)",
  // eslint-disable-next-line
  tooFewElementsInArray: "Il faut au minimum ${min} éléments",
  // eslint-disable-next-line
  tooMuchElementsInArray: "Il faut au maximum ${min} éléments",
};

export const ApiMessages = {
  networkError: "Problème réseau",
  successfulUpdate: "Changements enregistrés",
  partiallySuccessfulUpdate: "Changements partiellement enregistrés",
  emailValidated: "Adresse email enregistrée",
  requestSent: "Demande envoyée",
  passwordUpdated: "Votre mot de passe a bien été changé, vous pouvez maintenant vous connecter",
  userAdded: "Nouvel utilisateur enregistré",
  userDeleted: "Utilisateur supprimé",
  housingCreated: "Nouveau logement enregistré",
  housingDeleted: "Logement supprimé",
  noChange: "Aucun changement",
  visitCreated: "Nouvelle visite enregistrée",
  visitDeleted: "Visite supprimée",
  visitMandateSaved: "Accord enregistré, vous allez recevoir le bon de visite par email dans quelques instants",
};

export const AuthenticationMessages = {
  authenticationNeeded: "Vous devez vous identifier pour accéder à cette page",
  missingPermission: "Vous n'avez pas la permission d'accéder à cette page",
  invalidCredentials: "Identifiant ou mot de passe invalide",
};

export const DateFormat = "DD/MM/YYYY";
