import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  CircularProgress,
  CircularProgressProps,
  MenuItem,
  MenuItemProps,
  TableCell,
  TableRow,
  TableRowProps,
} from "@mui/material";
import { PropsWith } from "../../utils/PropsWith";

export function CenteredSpinner({
  CircularProgressProps,
  ...boxProps
}: BoxProps & Partial<PropsWith<"CircularProgressProps", CircularProgressProps>>) {
  return (
    <Box className="fullHeight verticallyCentered" alignItems="center" {...boxProps}>
      <CircularProgress variant="indeterminate" {...CircularProgressProps} />
    </Box>
  );
}

export function MenuItemSpinner(props: MenuItemProps) {
  const { sx, ...rest } = props;
  return (
    <MenuItem sx={{ display: "flex", justifyContent: "center", ...sx }} {...rest}>
      <CircularProgress variant="indeterminate" size={20} />
    </MenuItem>
  );
}

export function TableRowSpinner(props: TableRowProps) {
  return (
    <TableRow {...props}>
      <TableCell colSpan={1000} sx={{ textAlign: "center" }}>
        <CircularProgress variant="indeterminate" size={30} sx={{ my: 1 }} />
      </TableCell>
    </TableRow>
  );
}

export function ChipSpinner(props: ChipProps) {
  return (
    <Chip label={<CircularProgress variant="indeterminate" size={15} color="inherit" sx={{ mt: 0.5 }} />} {...props} />
  );
}
