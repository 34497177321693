import { ApiMessages } from "../constants/Strings";
import { Entity } from "./Entities";
import { Response } from "./Responses";

const ApiUrl = process.env.REACT_APP_API_URL ?? `${process.env.PUBLIC_URL}/api/`;

/**
 * Perform a POST request on *route* with
 *  - json stringified *data* as part of a form with name 'jsonData'
 *  - files as other parts
 */
async function POST<T extends Response>(
  route: string,
  data: any,
  files: Record<string, File> = {}
): Promise<ApiRequestResult<T>> {
  const formData = new FormData();
  formData.append("jsonData", JSON.stringify(data));
  Object.keys(files).forEach((f) => formData.append(f, files[f]));
  const opts = {
    method: "POST",
    body: formData,
  };
  return sendRequest(route, opts);
}

/**
 * Perform a GET request on *route*
 */
async function GET<T extends Entity | Entity[] | Response>(route: string): Promise<ApiRequestResult<T>> {
  const opts = {
    method: "GET",
  };
  return sendRequest(route, opts);
}

/**
 * Perform a PUT request on *route* with raw *data*
 */
async function PUT(route: string, data: any) {
  const opts = {
    method: "PUT",
    body: data,
  };
  return sendRequest(route, opts);
}

/**
 * Perform a PATCH request on *route* with *data* as a json string
 */
async function PATCH(route: string, data: any) {
  const opts = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return sendRequest(route, opts);
}

/**
 * Perform a DELETE request on *route*
 */
async function DELETE(route: string) {
  const opts = {
    method: "DELETE",
  };
  return sendRequest(route, opts);
}

interface ApiRequestResult<T> {
  success: boolean;
  httpStatus: number;
  errorReason?: string;
  data?: T;
  redirection?: string;
}

async function sendRequest<T extends Entity | Entity[] | Response>(
  url: string,
  opts: RequestInit
): Promise<ApiRequestResult<T>> {
  if (process.env.REACT_APP_API_FORCE_INCLUDE_CREDS === "true") opts.credentials = "include";

  const result: ApiRequestResult<T> = {
    success: false,
    httpStatus: 0,
  };

  try {
    if (!/^https?:\/\//.test(url)) url = `${ApiUrl}${url}`;
    const response = await fetch(url, opts);

    if (response.redirected && response.url.includes("/maintenance."))
      document.dispatchEvent(new CustomEvent(ApiEvents.MaintenanceInProgress));
    else if (response.status === 401) document.dispatchEvent(new CustomEvent(ApiEvents.Disconnected));

    result.httpStatus = response.status;
    if (response.ok) {
      result.success = true;
    } else {
      result.success = false;
      result.errorReason = response.statusText;
    }

    if (parseInt(response.headers.get("Content-Length") ?? "") > 0) {
      const contentType = response.headers.get("Content-Type");
      if (result.success)
        if (contentType === "application/json") result.data = (await response.json()) as T;
        else result.success = false;
      if (!result.success) result.errorReason = await response.text();
    }

    if (response.headers.has("Location")) result.redirection = response.headers.get("Location") ?? undefined;
  } catch {
    result.success = false;
    result.errorReason = ApiMessages.networkError;
    result.data = undefined;
  }
  return result;
}

export const Api = {
  url: ApiUrl,
  get: GET,
  post: POST,
  put: PUT,
  patch: PATCH,
  delete: DELETE,
};

export const ApiEvents = {
  MaintenanceInProgress: "api:failure:maintenance",
  Disconnected: "api:failure:unauthorized",
};
