import { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ApiEvents } from "../api/Api";
import { useAuth } from "../hooks/Auth";

export function ApiFailuresWatcher() {
  const auth = useAuth();

  const maintenanceHandler = useCallback(() => {
    if (process.env.NODE_ENV === "development")
      console.warn(`maintenance detected, should reload to trigger redirection to maintenance.html`);
    else window.location.reload();
  }, []);

  const disconnectionHandler = useCallback(() => {
    if (auth.loaded && Boolean(auth.user)) auth.refresh();
  }, [auth]);

  const onMaintenanceInProgress = useDebouncedCallback(maintenanceHandler, 3000, { leading: true, trailing: false });
  const onDisconnected = useDebouncedCallback(disconnectionHandler, 3000, { leading: true, trailing: false });

  useEffect(() => {
    document.addEventListener(ApiEvents.MaintenanceInProgress, onMaintenanceInProgress);
    document.addEventListener(ApiEvents.Disconnected, onDisconnected);

    return () => {
      document.removeEventListener(ApiEvents.MaintenanceInProgress, onMaintenanceInProgress);
      document.removeEventListener(ApiEvents.Disconnected, onDisconnected);
    };
  }, [onMaintenanceInProgress, onDisconnected]);

  return null;
}
