import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import fr from "dayjs/locale/fr";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { AppThemeProvider } from "./style/AppTheme";
import { ApiFailuresWatcher } from "./components/ApiFailuresWatcher";
import { ClosableSnackbarProvider } from "./components/ClosableSnackbarProvider";
import { AuthProvider } from "./hooks/Auth";
import { ScheduledTasksTicker } from "./components/ScheduledTasksTicker";

require("./utils/DayjsSetup");

export function App() {
  return (
    <AppThemeProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={fr}>
          <ClosableSnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "bottom" }} autoHideDuration={3000}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <ApiFailuresWatcher />
              <ScheduledTasksTicker />
              <AppRoutes />
            </BrowserRouter>
          </ClosableSnackbarProvider>
        </LocalizationProvider>
      </AuthProvider>
    </AppThemeProvider>
  );
}
