export enum Endpoints {
  Sessions = "sessions",
  Users = "users",
  Actions = "actions",
  Housings = "housings",
  TemporaryDirs = "temporaryDirs",
  Visuals = "visuals",
  Clients = "clients",
  Visits = "visits",
  LastViews = "lastViews",
  Views = "views",
  VisitMandates = "visitMandates",
}
