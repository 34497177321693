import { SnackbarMessage, useSnackbar } from "notistack";
import { useCallback } from "react";

export type Notifier = (msg: SnackbarMessage) => void;

type Notifiers = {
  notifyError: Notifier;
  notifySuccess: Notifier;
  notifyWarning: Notifier;
  notifyInfo: Notifier;
};

export function useNotifyError(): Notifier {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((msg) => enqueueSnackbar(msg, { variant: "error" }), [enqueueSnackbar]);
}

export function useNotifySuccess(): Notifier {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((msg) => enqueueSnackbar(msg, { variant: "success" }), [enqueueSnackbar]);
}

export function useNotifyWarning(): Notifier {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((msg) => enqueueSnackbar(msg, { variant: "warning" }), [enqueueSnackbar]);
}

export function useNotifyInfo(): Notifier {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((msg) => enqueueSnackbar(msg, { variant: "info" }), [enqueueSnackbar]);
}

export function useNotifiers(): Notifiers {
  const notifyError = useNotifyError();
  const notifySuccess = useNotifySuccess();
  const notifyWarning = useNotifyWarning();
  const notifyInfo = useNotifyInfo();

  return { notifyError, notifySuccess, notifyWarning, notifyInfo };
}
