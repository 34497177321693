import { useCallback, useState } from "react";

export function useResettableState<T>(defaultValue?: T): [T | undefined, (value: T | undefined) => void, () => void] {
  const [value, setValue] = useState(defaultValue);
  const resetValue = useCallback(() => setValue(defaultValue), [defaultValue, setValue]);
  return [value, setValue, resetValue];
}

export function useAlwaysDefinedResettableState<T>(defaultValue: T): [T, (value: T) => void, () => void] {
  const [value, setValue] = useState(defaultValue);
  const resetValue = useCallback(() => setValue(defaultValue), [defaultValue, setValue]);
  return [value, setValue, resetValue];
}
