import { Box } from "@mui/material";
import React, { ElementType, PropsWithChildren, Suspense, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RequireExactlyOne } from "type-fest";
import { Permission } from "../../api/Permission";
import { SqlRole } from "../../api/Roles";
import { AbsolutePages } from "../../constants/Sitemap";
import { AuthenticationMessages } from "../../constants/Strings";
import { Auth, useAuth } from "../../hooks/Auth";
import { useNotifiers } from "../../hooks/Notifiers";
import { RedirectAfterLogin } from "../../pages/auth/Login";
import { CenteredSpinner } from "../generic/Spinners";

interface Protections {
  requestedPermissions: Permission | Permission[];
  authorizeFunc: (auth: Auth) => boolean;
  requestedRoles: SqlRole | SqlRole[];
}

type OptionalUniqueProtection = Partial<RequireExactlyOne<Protections, keyof Protections>>;

type ProtectedContentProps = PropsWithChildren & OptionalUniqueProtection & { component?: ElementType };

enum AccessCheckResult {
  AuthenticationNeeded = 1,
  MissingPerm = 2,
  Granted = 3,
}

export function ProtectedContent({ children, component, ...authProps }: ProtectedContentProps) {
  const auth = useAuth();
  const location = useLocation();
  const { notifyWarning } = useNotifiers();
  const navigate = useNavigate();

  const result = useMemo(() => {
    if (!auth.loaded) return;

    if (auth.user == null) return AccessCheckResult.AuthenticationNeeded;

    let grantAccess = false;

    if (authProps.authorizeFunc) {
      grantAccess = authProps.authorizeFunc(auth);
    } else if (authProps.requestedPermissions) {
      if (typeof authProps.requestedPermissions === "string") {
        //single permission
        grantAccess = auth.hasPermission(authProps.requestedPermissions);
      } else {
        //array, need them all
        grantAccess = (authProps.requestedPermissions as Permission[]).every((perm) => auth.hasPermission(perm));
      }
    } else if (authProps.requestedRoles) {
      if (typeof authProps.requestedRoles === "string") {
        //only one possible role
        grantAccess = auth.user.role === authProps.requestedRoles;
      } else {
        //one of array
        grantAccess = (authProps.requestedRoles as SqlRole[]).some((role) => auth.user?.role === role);
      }
    } else {
      grantAccess = true;
    }

    return grantAccess ? AccessCheckResult.Granted : AccessCheckResult.MissingPerm;
  }, [auth, authProps]);

  useEffect(() => {
    if (!result || result === AccessCheckResult.Granted) return;

    if (result === AccessCheckResult.AuthenticationNeeded) {
      notifyWarning(AuthenticationMessages.authenticationNeeded);
      navigate(AbsolutePages.Login, { replace: true, state: { to: location } as RedirectAfterLogin });
    } else if (result === AccessCheckResult.MissingPerm) {
      notifyWarning(AuthenticationMessages.missingPermission);
      navigate("/");
    }
  }, [result, location, notifyWarning, navigate]);

  const cloneWithAuth = (child: any) => React.cloneElement(child, { auth: auth });

  return (
    <Suspense fallback={<CenteredSpinner />}>
      {result === AccessCheckResult.Granted &&
        React.createElement(component ?? Box, { auth: auth }, React.Children.map(children, cloneWithAuth))}
    </Suspense>
  );
}
