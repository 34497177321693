import Big from "big.js";
import { Dayjs } from "dayjs";
import { clone, isArray } from "lodash";
import { SyntheticEvent } from "react";

export function pluralMark(number: number = 0, upperCase: boolean = false): string {
  return number > 1 ? (upperCase ? "S" : "s") : "";
}

export function zeroOrMoreVariant(number: number, zeroVariant: string, moreVariant: string): string {
  return number === 0 ? zeroVariant : moreVariant;
}

export function oneOrMoreVariant(number: number, oneVariant: string, moreVariant: string): string {
  return number === 1 ? oneVariant : moreVariant;
}

export function zeroOneOrMoreVariant(
  number: number,
  zeroVariant: string,
  oneVariant: string,
  moreVariant: string
): string {
  return number === 0 ? zeroVariant : number === 1 ? oneVariant : moreVariant;
}

export function isFilePngOrJpg(file: File): boolean {
  return ["image/jpeg", "image/png"].includes(file?.type);
}

export function isFileNotTooBig(file: File, maxSizeInMegaBytes: number): boolean {
  return file?.size / 1024 / 1024 <= maxSizeInMegaBytes;
}

export function discardEvent(): void {}

export function preventDefault(e: SyntheticEvent): void {
  e.preventDefault();
}

export function stopEventPropagation(e: SyntheticEvent): void {
  e.stopPropagation();
}

export function sleep(milliseconds: number): Promise<void> {
  return new Promise((r) => setTimeout(r, milliseconds));
}

export function basename(path: string): string {
  let filename = path.split(/[\\/]/).pop() as string; //there will always be one entry
  const dotPos = filename?.lastIndexOf(".");
  if (dotPos !== -1) filename = filename?.substring(0, dotPos);
  return filename;
}

// https://stackoverflow.com/a/37511463/829728
export function removeAccents(str: string): string {
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export function localeLowerCompare(str1: string, str2: string) {
  return str1.toLocaleLowerCase().localeCompare(str2.toLocaleLowerCase());
}

export function toNameCase(str: string): string {
  let previousWasDelimiter = true;
  let newString = "";
  str = str.toLocaleLowerCase();
  for (let i = 0; i < str.length; i++) {
    let c = str[i];
    if ([" ", "'", "-"].some((delim) => delim === c)) previousWasDelimiter = true;
    else {
      if (previousWasDelimiter) c = c.toLocaleUpperCase();
      previousWasDelimiter = false;
    }
    newString += c;
  }
  return newString;
}

export function createArray<T>(length: number, generator: (() => T) | ((index: number) => T)): T[] {
  const array = Array(length);
  for (let i = 0; i < length; i++) array[i] = generator(i);
  return array;
}

export function enumerate(start: number, end: number) {
  const array = Array(end - start + 1);
  for (let i = start; i <= end; i++) array[i - start] = i;
  return array;
}

export function formatPhone(phone?: string): string {
  if (!phone) return "";
  phone = phone.replace(/[^+\d]/g, "");
  if (phone[0] === "0")
    // french 10 digits format
    return phone.replace(/\d\d/g, "$& ").trimEnd();
  //three digits grouping may cut country prefixes and end with lone digits: we don't care, it's just easier to read.
  return phone.replace(/\d\d\d/g, "$& ").trimEnd();
}

const priceFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
});
export function formatPrice(price: Big): string {
  return priceFormatter.format(price.toNumber());
}

const surfaceFormatter = new Intl.NumberFormat("fr-FR", {
  style: "decimal",
});
export function formatSurface(surface: number): string {
  // Intl doesn't handle squared units (yet)
  // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#unit
  return surfaceFormatter.format(surface) + "\u00a0m²";
}

export function truncateString(str: string | null | undefined, maxLength: number) {
  if (!str) return str;
  return str.length > maxLength ? str.substring(0, maxLength - 1) + "\u2026" : str;
}

export function objectDiff<T extends {}>(previous: T, current: T) {
  const diff: Record<string, any> = {};
  const previousKeys = Object.keys(previous);
  previousKeys.forEach((k) => {
    const kk = k as keyof typeof previous;
    if (current[kk] !== previous[kk]) {
      if (typeof previous[kk] === "object" && typeof current[kk] === "object" && current[kk] !== null) {
        const subDiff = objectDiff(previous[kk] as {}, current[kk] as {});
        if (Object.keys(subDiff).length > 0) diff[k] = subDiff;
      } else diff[k] = current[kk];
    }
  });
  Object.keys(current).forEach((k) => {
    if (previousKeys.includes(k)) return;
    const kk = k as keyof typeof current;
    diff[k] = current[kk];
  });
  return diff;
}

export type NumberRange = {
  min: number;
  max: number;
};

export function alignRangeForStep(range: NumberRange, step: number): NumberRange {
  const alignedRange = clone(range);
  alignedRange.min -= alignedRange.min % step;
  alignedRange.max = Math.ceil(alignedRange.max / step) * step;
  return alignedRange;
}

// php doesn't handle arrays in a standard way (https://www.php.net/manual/fr/function.parse-str.php#76792)
// so we can't use URLSearchParams
export function toPhpQueryString(searchParams: Record<string, any>): string {
  return Object.keys(searchParams)
    .map((key) => {
      const value = searchParams[key];
      if (isArray(value)) {
        const array = searchParams[key] as Array<any>;
        return array
          .map((arrayValue) => {
            return encodeURIComponent(key) + "[]=" + encodeURIComponent(arrayValue);
          })
          .join("&");
      } else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(value);
      }
    })
    .join("&");
}

export function compareStrings(s1: string | null | undefined, s2: string | null | undefined) {
  return (s1?.toLocaleLowerCase() ?? "").localeCompare(s2?.toLocaleLowerCase() ?? "");
}

export function comparePrices(n1: Big | null | undefined, n2: Big | null | undefined) {
  return (n1 ?? Big(0)).minus(n2 ?? Big(0)).toNumber();
}

export function compareNames<WithName extends { name: string }>(i1: WithName, i2: WithName) {
  return compareStrings(i1.name, i2.name);
}

export function compareBooleans(a: boolean, b: boolean) {
  return (a ? 1 : 0) - (b ? 1 : 0);
}

export function compareDates(a: Dayjs | null | undefined, b: Dayjs | null | undefined) {
  return (a?.valueOf() ?? 0) - (b?.valueOf() ?? 0);
}

export function groupBy<T>(items: T[] | undefined, keySelector: (item: T) => string): Record<string, T[]> | undefined {
  return items?.reduce((acc, item) => {
    const key = keySelector(item);
    if (!acc[key]) acc[key] = [item];
    else acc[key].push(item);
    return acc;
  }, {} as Record<string, T[]>);
}

export type Comparator<T> = (a: T, b: T) => number;

//heavily inspired from https://mui.com/material-ui/react-avatar/
export function stringToColor(str?: string | null) {
  if (!str || str.length === 0) return undefined;
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) hash = str.charCodeAt(i) + ((hash << 5) - hash);

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function makeMultipleFilter(filterName: string, ...values: string[]): string {
  return `${filterName}[]=${values.join(`&${filterName}[]=`)}`;
}
