import dayjs from "dayjs";
import fr from "dayjs/locale/fr";

dayjs.locale(fr);

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

//we need to export something for this file compile with --isolatedModules
export {};
