import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { Api } from "../api/Api";
import { ApiCache } from "../api/ApiCache";
import { Endpoints } from "../api/Endpoints";
import { Session } from "../api/Entities";
import { Permission } from "../api/Permission";
import { AuthenticationMessages } from "../constants/Strings";
import { useResettableState } from "./ResettableState";

export type LoadingAuth = {
  loaded: false;
};

export type Auth = {
  loaded: true;
  user?: Session["user"];
  error?: string;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  hasPermission: (permission: Permission) => boolean;
  refresh: () => void;
};

const authContext = createContext<LoadingAuth | Auth>({ loaded: false });

export interface PropsWithAuth {
  auth: Auth;
}

//component that wraps everything that needs to access auth status
export function AuthProvider({ children }: PropsWithChildren) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

//hook to be used by any component inside ProvideAuth
export function useAuth() {
  return useContext(authContext);
}

//the real stuff
function useAuthProvider(): Auth | LoadingAuth {
  const [user, setUser, resetUser] = useResettableState<Session["user"]>();
  const [error, setError, resetError] = useResettableState<string>();
  const [loaded, setLoaded] = useState(false);

  const getUserFromSession = useCallback(async () => {
    const result = await Api.get<Session>(`${Endpoints.Sessions}/current`);
    if (result.success && result.httpStatus === 200) setUser((await result.data)?.user);
    else resetUser();
    setLoaded(true);
  }, [setUser, resetUser, setLoaded]);

  const signIn = async (email: string, password: string) => {
    resetError();
    ApiCache.clearAll();
    const result = await Api.post(Endpoints.Sessions, { email, password });
    if (result.success && result.httpStatus === 201) getUserFromSession();
    else {
      resetUser();
      let error = null;
      switch (result.httpStatus) {
        case 400:
          error = AuthenticationMessages.invalidCredentials;
          break;
        default:
          error = result.errorReason;
          break;
      }
      setError(error);
    }
  };

  const signOut = async () => {
    resetError();
    const result = await Api.delete(`${Endpoints.Sessions}/current`);
    if (result.success) {
      resetUser();
      ApiCache.clearAll();
    } else setError(result.errorReason);
  };

  const hasPermission = (permName: Permission) => {
    return user?.permissions[permName] === true;
  };

  const refresh = () => {
    getUserFromSession();
  };

  useEffect(() => {
    getUserFromSession();
  }, [getUserFromSession]);

  return !loaded ? { loaded } : { loaded, user, error, signIn, signOut, hasPermission, refresh };
}
