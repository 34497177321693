import { useCallback, useEffect, useState } from "react";
import { ApiMessages } from "../constants/Strings";
import { useNotifiers } from "../hooks/Notifiers";
import { sleep } from "../utils/Utils";
import { Api } from "./Api";
import { Entity } from "./Entities";

/**
 * Volatile cache (cleared when refreshing the page) used in fetchObject & fetchArray
 */

type CacheEntry = {
  data: Entity | Entity[] | undefined;
  timestamp: number;
};

const cache: Record<string, CacheEntry> = {};
const cacheMaxValidity = 5 * 60 * 1000; //5 min
const maxFetchAttempts = 10;

//#region fetching (hooks)

export function useAutoRefreshObject<E extends Entity>(
  route: string,
  condition: boolean = true,
  transformer?: (item: E) => void
) {
  return useAutoRefreshData<E>(route, fetchObject, condition, transformer);
}

export function useAutoRefreshArray<E extends Entity>(
  route: string,
  condition: boolean = true,
  transformer?: (item: E) => void
) {
  const arrayTransformer = useCallback(
    (items: E[]) => {
      if (transformer) items.forEach(transformer);
    },
    [transformer]
  );
  return useAutoRefreshData<E[]>(route, fetchArray, condition, arrayTransformer);
}

type ErrorHandler = (msg: string) => void;
type FetchFunction<T> = (route: string, errorHandler: ErrorHandler) => Promise<T | undefined>;

function useAutoRefreshData<T extends Entity | Entity[]>(
  route: string,
  fetchFunc: FetchFunction<T>,
  condition: boolean,
  transformer?: (data: T) => void
) {
  const [value, setValue] = useState<T>();
  const { notifyError } = useNotifiers();

  useEffect(() => {
    let active = true;

    const refresh = async () => {
      if (!condition) return;
      const data = await fetchFunc(route, notifyError);
      if (data && transformer) transformer(data);
      if (active) setValue(data);
    };

    refresh();
    document.addEventListener(`apiCache:invalidated:${route}`, refresh);

    return () => {
      document.removeEventListener(`apiCache:invalidated:${route}`, refresh);
      active = false;
    };
  }, [route, fetchFunc, condition, setValue, notifyError, transformer]);

  return value;
}

//#endregion

//#region fetching (imperative)

/**
 * Retrieve parsed data from *route*
 *
 * Performs a GET request and fetch the result as a single object, assuming
 * that the response will have a json-encoded body.
 * Result may be stored/retrieved from cache.
 */
function fetchObject<E extends Entity>(route: string, notifyErrorFunc: ErrorHandler): Promise<E | undefined> {
  return fetchDataFromCache<E>(route, _fetchObject, notifyErrorFunc);
}

/**
 * Retrieve parsed data from *route*
 *
 * Performs a GET request and fetch the result as an array, assuming
 * that the response will have a json-encoded body.
 * Result may be stored/retrieved from cache.
 */
function fetchArray<E extends Entity>(route: string, notifyErrorFunc: ErrorHandler): Promise<E[] | undefined> {
  return fetchDataFromCache<E[]>(route, _fetchArray, notifyErrorFunc);
}

async function fetchDataFromCache<T extends Entity | Entity[]>(
  route: string,
  fetchFunc: FetchFunction<T>,
  notifyErrorFunc: ErrorHandler
): Promise<T | undefined> {
  const now = new Date().getTime();
  if (cache[route] && now - cache[route].timestamp < cacheMaxValidity) return cache[route].data as T;
  const result = await fetchFunc(route, notifyErrorFunc);
  if (result) cache[route] = { data: result, timestamp: now };
  return result;
}

async function _fetchObject<E extends Entity>(
  route: string,
  notifyErrorFunc: ErrorHandler,
  attempt: number = 1
): Promise<E | undefined> {
  const result = await Api.get<E>(route);
  if (
    !result.success ||
    (result.httpStatus !== 200 && result.httpStatus !== 204) ||
    !result.data !== (result.httpStatus === 204)
  ) {
    console.warn(`Unable to fetch object from ${route}. Reason: ${result.errorReason}.`);
    if (result.errorReason === ApiMessages.networkError) {
      if (attempt < maxFetchAttempts) {
        console.log("Retrying in one second...");
        await sleep(1000);
        return await _fetchObject(route, notifyErrorFunc, ++attempt);
      } else {
        console.error("10 tries failed, giving up.");
        notifyErrorFunc(result.errorReason);
      }
    } else if (result.errorReason) {
      notifyErrorFunc(result.errorReason);
    }
    return;
  }
  if (!result.data) return;
  return await result.data;
}

async function _fetchArray<E extends Entity>(
  route: string,
  notifyErrorFunc: ErrorHandler,
  attempt: number = 1
): Promise<E[] | undefined> {
  const result = await Api.get<E[]>(route);
  if (
    !result.success ||
    (result.httpStatus !== 200 && result.httpStatus !== 204) ||
    !result.data !== (result.httpStatus === 204)
  ) {
    console.warn(`Unable to fetch array from ${route}. Reason: ${result.errorReason}.`);
    if (result.errorReason === ApiMessages.networkError) {
      if (attempt < maxFetchAttempts) {
        console.log("Retrying in one second...");
        await sleep(1000);
        return await _fetchArray(route, notifyErrorFunc, ++attempt);
      } else {
        console.error("10 tries failed, giving up.");
        notifyErrorFunc(result.errorReason);
      }
    } else if (result.errorReason) {
      notifyErrorFunc(result.errorReason);
    }
    return;
  }
  if (!result.data) return [];
  return await result.data;
}
//#endregion

//#region clearing / invalidating

function clearRoutes(...routesArray: string[]): void {
  clearCache(routesArray, false);
}

function invalidateRoutes(...routesArray: string[]): void {
  clearCache(routesArray, true);
}

function clearAll(): void {
  clearCache(null, false);
}

function invalidateAll(): void {
  clearCache(null, true);
}

/**
 * Clears entries from cache:
 * @param routes array of route to clear
 * @param notify notify subscribers?
 *
 * - if *routes* is null all entries are cleared
 * - if *routes* is present:
 *      + each route ending with '\*' leads to clearing entries for routes starting with the same root (before '\*')
 *      + each other route with anything else leads to clearing only this exact route
 */
function clearCache(routes: string[] | null, notify: boolean) {
  const clean = (key: string) => {
    delete cache[key];
    if (notify) document.dispatchEvent(new CustomEvent(`apiCache:invalidated:${key}`));
  };

  if (routes == null) {
    Object.keys(cache).forEach(clean);
  } else {
    routes.forEach((route) => {
      if (route.endsWith("*")) {
        route = route.substring(0, route.length - 1);
        Object.keys(cache).forEach((key) => {
          if (key.startsWith(route)) clean(key);
        });
      } else clean(route);
    });
  }
}

//#endregion

export const ApiCache = {
  fetchObject,
  fetchArray,
  clearRoutes,
  invalidateRoutes,
  clearAll,
  invalidateAll,
};
