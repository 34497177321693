export const RoutesTokens = {
  housingRef: ":housingRef",
};

export const AbsolutePages = {
  Login: "/connexion",
  PasswordReset: "/reinitialisation-mdp",
  PasswordSetup: "/creation-mdp",
  CurrentUserAccount: "/mon-compte",
  EmailValidation: "/confirmation-email",
  AccessRequest: "/demander-un-acces",

  Admin: {
    Home: "/admin",

    Housings: "/admin/logements",
    HousingCreation: `/admin/logements/nouveau`,
    HousingEdition: `/admin/logements/${RoutesTokens.housingRef}`,

    Visits: `/admin/visites`,
    VisitCreation: `/admin/visites/nouvelle`,
  },

  Client: {
    Home: "/prospect",
    HousingView: `/prospect/logements/${RoutesTokens.housingRef}`,
  },
};

export const RelativePages = {
  Admin: {
    Housings: `logements`,
    HousingCreation: `logements/nouveau`,
    HousingEdition: `logements/${RoutesTokens.housingRef}`,

    Visits: `visites`,
    VisitCreation: `visites/nouvelle`,
  },
};
