import { useEffect } from "react";
import { Actions } from "../api/Actions";
import { Api } from "../api/Api";
import { Endpoints } from "../api/Endpoints";

export function ScheduledTasksTicker() {
  const delay =
    process.env.NODE_ENV === "development"
      ? 60 * 1000 // 1 min
      : 5 * 60 * 1000; // 5 min

  const tick = () => {
    Api.post(Endpoints.Actions, { action: Actions.CheckScheduledTasks });
  };

  useEffect(() => {
    tick();
    const intervalId = setInterval(tick, delay);
    return () => {
      clearInterval(intervalId);
    };
  });

  return null;
}
