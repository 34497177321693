export enum SqlRole {
  tech = "tech",
  super_admin = "super_admin",
  admin = "admin",
  client = "client",
}

export const Roles: Record<string, SqlRole> = {
  Tech: SqlRole.tech,
  SuperAdmin: SqlRole.super_admin,
  Admin: SqlRole.admin,
  Client: SqlRole.client,
};

export const RolesNames: Record<SqlRole, string> = {
  tech: "Tech",
  super_admin: "Super Administrateur",
  admin: "Administrateur",
  client: "Client",
};
