import { Suspense, lazy, useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Roles } from "./api/Roles";
import { ProtectedContent } from "./components/routing/ProtectedContent";
import { AbsolutePages } from "./constants/Sitemap";
import { useAuth } from "./hooks/Auth";
import { useNotifyWarning } from "./hooks/Notifiers";

const Login = lazy(() => import("./pages/auth/Login"));
const PasswordReset = lazy(() => import("./pages/auth/PasswordReset"));
const EmailValidation = lazy(() => import("./pages/auth/EmailValidation"));
const AdminHome = lazy(() => import("./pages/admin/AdminHome"));
const ClientHome = lazy(() => import("./pages/client/ClientHome"));
const HousingView = lazy(() => import("./pages/client/HousingView"));
const AccessRequest = lazy(() => import("./pages/AccessRequest"));
const MyAccount = lazy(() => import("./pages/MyAccount"));

export function AppRoutes() {
  return (
    <Suspense>
      <Routes>
        <Route index element={<Home />} />

        <Route path={AbsolutePages.Login} element={<Login />} />
        <Route path={AbsolutePages.PasswordReset} element={<PasswordReset />} />
        <Route path={AbsolutePages.PasswordSetup} element={<PasswordReset />} />
        <Route path={AbsolutePages.EmailValidation} element={<EmailValidation />} />

        <Route path={AbsolutePages.AccessRequest} element={<AccessRequest />} />

        <Route path={AbsolutePages.CurrentUserAccount} element={<ProtectedContent component={MyAccount} />} />

        <Route
          path={`${AbsolutePages.Admin.Home}/*`}
          element={
            <ProtectedContent requestedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Tech]} component={AdminHome} />
          }
        />

        <Route
          path={AbsolutePages.Client.Home}
          element={<ProtectedContent requestedRoles={Roles.Client} component={ClientHome} />}
        />
        <Route
          path={AbsolutePages.Client.HousingView}
          element={<ProtectedContent requestedRoles={Roles.Client} component={HousingView} />}
        />

        <Route path="*" element={<DebugCatchAll />} />
      </Routes>
    </Suspense>
  );
}

function Home() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.loaded) return;
    if (auth.loaded && !auth.user) navigate(AbsolutePages.Login, { replace: true });
  }, [auth, navigate]);

  if (!auth.loaded || !auth.user) return null;

  return <Navigate to={auth.user.role === Roles.Client ? AbsolutePages.Client.Home : AbsolutePages.Admin.Home} />;
}

function DebugCatchAll() {
  const notifyWarning = useNotifyWarning();
  const navigate = useNavigate();
  const fired = useRef(false);

  console.log(useLocation().pathname);

  useEffect(() => {
    if (fired.current) return;
    fired.current = true;
    notifyWarning("Cette page n'existe pas (encore)");
    navigate("/");
  }, [notifyWarning, navigate]);

  return null;
}
