import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useMemo } from "react";

export function AppThemeProvider(props: Omit<ThemeProviderProps, "theme">) {
  const theme = useMemo(() => getAppTheme(), []);
  const { children, ...rest } = props;
  return (
    <ThemeProvider theme={theme} {...rest}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
}

function getAppTheme() {
  const white = "#ffffff";
  const darkGray = "#707070";
  const lightGray = "#f2f2f2";
  const greenish = "#8c9f8c";

  let theme = createTheme({
    components: {
      MuiCard: {
        defaultProps: {
          elevation: 0,
          style: {
            backgroundColor: lightGray,
            borderRadius: "32px",
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            fontSize: "20px",
            textAlign: "center",
            paddingTop: "30px",
          },
          subheaderTypographyProps: {
            fontSize: "14px",
            textAlign: "center",
            paddingTop: "10px",
          },
        },
      },
      MuiCardContent: {
        defaultProps: {
          sx: {
            display: "flex",
            flexDirection: "column",
          },
        },
      },
      MuiCardActions: {
        defaultProps: {
          sx: {
            justifyContent: "flex-end",
            px: 2,
            pb: 2,
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          fullWidth: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            backgroundColor: white,
            borderRadius: 0,
          },
        },
        styleOverrides: {
          notchedOutline: {
            borderColor: darkGray,
            borderWidth: "1px",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        defaultProps: {
          disableElevation: true,
        },
      },

      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },

      MuiDialogContent: {
        defaultProps: {
          sx: {
            width: 400,
          },
        },
      },
      MuiStack: {
        defaultProps: {
          gap: 2,
        },
      },
      MuiTooltip: {
        defaultProps: {
          placement: "top",
          arrow: true,
        },
      },
      MuiSkeleton: {
        defaultProps: {
          variant: "rectangular",
        },
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      primary: theme.palette.augmentColor({ color: { main: darkGray } }),
      secondary: theme.palette.augmentColor({ color: { main: greenish } }),
      common: {
        lightGray,
        darkGray,
      },
      text: {
        primary: darkGray,
      },
    },
  });

  return theme;
}
