import { Button } from "@mui/material";
import { SnackbarProvider, SnackbarProviderProps, closeSnackbar } from "notistack";

export function ClosableSnackbarProvider(props: SnackbarProviderProps) {
  return (
    <SnackbarProvider
      action={(k) => (
        <Button
          onClick={() => closeSnackbar(k)}
          sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }}
        />
      )}
      {...props}
    />
  );
}
